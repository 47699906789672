// frontend/src/components/Sidebar.tsx
import React from 'react';
import { FileText, MessageSquare, Settings } from 'lucide-react';

interface SidebarProps {
  onNewCase?: () => void;
  onViewCases?: () => void;
  onSettings?: () => void;
}

export const Sidebar: React.FC<SidebarProps> = ({
  onNewCase,
  onViewCases,
  onSettings
}) => {
  return (
    <div className="w-64 bg-gray-50 shadow-sm border-b h-full">
      <div className="p-4">
        <h2 className="text-xl font-bold mb-4 text-left">Campusbridge</h2>
        <nav className="space-y-2">
          <button
            onClick={onNewCase}
            className="flex items-center justify-start space-x-2 w-full p-2 rounded hover:bg-gray-100"
          >
            <FileText size={20} />
            <span className="ml-2"><a href="/">新案例</a></span>
          </button>
          <button
            onClick={onViewCases}
            className="flex items-center justify-start space-x-2 w-full p-2 rounded hover:bg-gray-100"
          >
            <MessageSquare size={20} />
            <span className="ml-2"><a href="/active-cases">開放的案件</a></span>
          </button>
          <button
            onClick={onSettings}
            className="flex items-center justify-start space-x-2 w-full p-2 rounded hover:bg-gray-100"
          >
            <Settings size={20} />
            <span className="ml-2">设置</span>
          </button>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;