// frontend/src/services/api.ts
import axios, { AxiosError } from 'axios';
import { CaseDetails, Recommendation } from '../types/case';

// const API_URL = 'http://127.0.0.1:8000/api';
const API_URL = 'https://student-back-production.up.railway.app/api';

const api = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
    auth: {
        username: 'arsh',
        password: '66426623'
    }
});

// Add request interceptor to handle CSRF token
api.interceptors.request.use(
    (config) => {
        const csrfToken = document.cookie
            .split('; ')
            .find(row => row.startsWith('csrftoken='))
            ?.split('=')[1];

        if (csrfToken) {
            config.headers['X-CSRFToken'] = csrfToken;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

interface CaseResponse extends CaseDetails {
    id: number;
}

interface RecommendationResponse {
    recommendation: Recommendation;
}

export const caseService = {
    submitCase: async (caseDetails: CaseDetails) => {
        try {
            const response = await api.post<CaseResponse>('/case_students/', caseDetails);
            return response;
        } catch (error) {
            const axiosError = error as AxiosError;
            console.error('API Error:', axiosError.response?.data);
            throw error;
        }
    },
    getCases: async () => {
        try {
            const response = await api.get<CaseResponse[]>('/case_students/');
            return response;
        } catch (error) {
            const axiosError = error as AxiosError;
            console.error('API Error:', axiosError.response?.data);
            throw error;
        }
    },
    requestJudgment: async (caseId: number) => {
        try {
            const response = await api.post<RecommendationResponse>(`/case_students/${caseId}/request_recommendation/`);
            return response;
        } catch (error) {
            const axiosError = error as AxiosError;
            console.error('API Error:', axiosError.response?.data);
            throw error;
        }
    },
};

