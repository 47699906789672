import React from "react";
import { User, Menu } from "lucide-react";

interface HeaderProps {}

export const Header: React.FC<HeaderProps> = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  return (
    <header className="bg-gray-50 shadow-sm border-b">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <div className="flex items-center">
            <h1 className="text-xl md:text-2xl font-semibold text-gray-800">
              校区推荐系统
            </h1>
          </div>

          <div className="hidden md:flex items-center space-x-4">
            <span className="text-gray-600">Campusbridge</span>
            <User className="w-6 h-6 text-gray-600 hover:text-gray-800 cursor-pointer" />
          </div>

          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-gray-600 hover:text-gray-800"
            >
              <Menu className="w-6 h-6" />
            </button>
          </div>
        </div>

        {isMenuOpen && (
          <div className="md:hidden py-2 space-y-2 border-t">
            <div className="flex items-center justify-end space-x-4 px-2 py-2">
              <span className="text-gray-600">学生</span>
              <User className="w-6 h-6 text-gray-600" />
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
