import React from "react";
import { Recommendation } from "../types/case";

interface JudgmentDisplayProps {
  recommendation: Recommendation;
}

export const RecommendationDisplay: React.FC<JudgmentDisplayProps> = ({
  recommendation,
}) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md text-left">
      <h2 className="text-2xl font-bold mb-4 text-center">建议</h2>
      <div className="whitespace-pre-wrap text-left">
        {parseRecommendationText(recommendation.recommendation_text)}
      </div>
      <div className="mt-4 text-gray-600">
        Issued on: {new Date(recommendation.date_issued).toLocaleDateString()}
      </div>
    </div>
  );
};

export default RecommendationDisplay;

export const parseRecommendationText = (text: string) => {
  try {
    const match = text.match(/text='([^']+)'/);
    if (match) {
      return match[1].replace(/\\n/g, "\n").replace(/\\/g, "");
    }
    return text;
  } catch (error) {
    return text;
  }
};
