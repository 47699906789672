import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { CaseManagement } from "./pages/CaseManagement";
import ActiveCases from "./pages/ActiveCases";
import Sidebar from "./components/Sidebar";
import { Header } from "./components/Header";
// import CaseDetail from "./pages/CaseDetail";

function App() {
  return (
    <Router>
      <Header />

      <div className="flex">
        <Sidebar  />
        <div className="w-full">
          <Routes>
            <Route path="/" element={<CaseManagement />} />
            <Route path="/active-cases" element={<ActiveCases />} />
            {/* <Route path="/case-detail" element={<CaseDetail />} /> */}
          </Routes>
        </div>
      </div>
    </Router>
  );
} 

export default App;
