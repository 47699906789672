import React, { useEffect, useState } from "react";
import { caseService } from "../services/api";
import { CaseDetails } from "../types/case";
import { parseRecommendationText } from "../components/JudgmentDisplay";

interface Case extends CaseDetails {
  id: number;
  created_at: string;
  description?: string;
}
const ActiveCases: React.FC = () => {
  const [cases, setCases] = useState<Case[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedCase, setSelectedCase] = useState<Case | null>(null);

  useEffect(() => {
    fetchCases();
  }, []);

  const fetchCases = async () => {
    try {
      const response = await caseService.getCases();
      setCases(
        response.data.map((caseResponse) => ({
          ...caseResponse,
          created_at: new Date().toISOString(), // Add missing created_at field
        }))
      );
    } catch (err) {
      setError("Failed to load cases");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const toggleRow = (case_: Case) => {
    setSelectedCase(case_);
  };

  const handleBack = () => {
    setSelectedCase(null);
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <div className="flex-1 flex flex-col">
        <div className="p-6">
          {selectedCase ? (
            <div className="text-left">
              <div className="flex justify-between items-center mb-6">
                <button
                  onClick={handleBack}
                  className="px-4 py-2 bg-blue-500 text-white rounded"
                >
                  返回
                </button>
                <h1 className="text-2xl font-bold">案件細節</h1>
              </div>
              <div className="space-y-6 bg-white rounded-lg shadow-md p-8 text-left">
                <p className="flex items-center border-b pb-4 justify-start">
                  <strong className="text-lg mx-2 text-gray-700">
                    案件编号:
                  </strong>
                  <span className="text-gray-600">
                    {selectedCase.case_number}
                  </span>
                </p>
                <p className="flex items-center border-b pb-4 justify-start">
                  <strong className="text-lg mx-2 text-gray-700">
                    全名:
                  </strong>
                  <span className="text-gray-600">
                    {selectedCase.full_name}
                  </span>
                </p>
                <p className="flex items-center border-b pb-4 justify-start">
                  <strong className="text-lg mx-2 text-gray-700">
                    国籍:
                  </strong>
                  <span className="text-gray-600">
                    {selectedCase.nationality}
                  </span>
                </p>
                <p className="flex items-center border-b pb-4 justify-start">
                  <strong className="text-lg mx-2 text-gray-700">年龄:</strong>
                  <span className="text-gray-600">{selectedCase.age}</span>
                </p>
                <p className="flex items-center border-b pb-4 justify-start">
                  <strong className="text-lg mx-2 text-gray-700">
                    教育背景:
                  </strong>
                  <span className="text-gray-600">
                    {selectedCase.education_background}
                  </span>
                </p>
                <p className="flex items-center border-b pb-4 justify-start">
                  <strong className="text-lg mx-2 text-gray-700">
                    兴趣:
                  </strong>
                  <span className="text-gray-600">
                    {selectedCase.interests}
                  </span>
                </p>
                <p className="flex items-center border-b pb-4 justify-start">
                  <strong className="text-lg mx-2 text-gray-700">
                    目标国家:
                  </strong>
                  <span className="text-gray-600">
                    {selectedCase.target_country}
                  </span>
                </p>
                <p className="flex items-center border-b pb-4 justify-start">
                  <strong className="text-lg mx-2 text-gray-700">
                    目标专业:
                  </strong>
                  <span className="text-gray-600">
                    {selectedCase.target_major}
                  </span>
                </p>
                <p className="flex items-center border-b pb-4 justify-start">
                  <strong className="text-lg mx-2 text-gray-700">
                    学术成绩:
                  </strong>
                  <span className="text-gray-600">
                    {selectedCase.academic_score}
                  </span>
                </p>
                <p className="flex items-center border-b pb-4 justify-start">
                  <strong className="text-lg mx-2 text-gray-700">
                    成绩类型:
                  </strong>
                  <span className="text-gray-600">
                    {selectedCase.score_type}
                  </span>
                </p>
                <p className="flex items-center border-b pb-4 justify-start">
                  <strong className="text-lg mx-2 text-gray-700">
                    语言能力:
                  </strong>
                  <span className="text-gray-600">
                    {selectedCase.language_proficiency}
                  </span>
                </p>
                <p className="flex items-center border-b pb-4 justify-start">
                  <strong className="text-lg mx-2 text-gray-700">
                    签证要求:
                  </strong>
                  <span className="text-gray-600">
                    {selectedCase.visa_requirement}
                  </span>
                </p>
                <p className="flex items-center border-b pb-4 justify-start">
                  <strong className="text-lg mx-2 text-gray-700">
                    住宿需求:
                  </strong>
                  <span className="text-gray-600">
                    {selectedCase.accommodation_needs}
                  </span>
                </p>
                <p className="flex items-center border-b pb-4 justify-start">
                  <strong className="text-lg mx-2 text-gray-700">
                    年度预算:
                  </strong>
                  <span className="text-gray-600">
                    {selectedCase.annual_budget}
                  </span>
                </p>
                <p className="flex items-center border-b pb-4 justify-start">
                  <strong className="text-lg mx-2 text-gray-700">
                    提供的文件:
                  </strong>
                  <span className="text-gray-600">
                    {selectedCase.provided_documents}
                  </span>
                </p>
                <p className="flex items-center border-b pb-4 justify-start">
                  <strong className="text-lg mx-2 text-gray-700">
                    需要附加信息：
                  </strong>
                  <span className="text-gray-600">
                    {selectedCase.additional_needs}
                  </span>
                </p>
                <p className="flex items-center border-b pb-4 justify-start">
                  <strong className="text-lg mx-2 text-gray-700">
                    狀態：
                  </strong>
                  <span className="text-gray-600">{selectedCase.status}</span>
                </p>
                <p className="flex items-center border-b pb-4 justify-start">
                  <strong className="text-lg mx-2 text-gray-700">
                    AI 建议：
                  </strong>
                  <span className="text-gray-600">
                    {parseRecommendationText(selectedCase.ai_recommendation)}
                  </span>
                </p>
                <p className="flex items-center border-b pb-4 justify-start">
                  <strong className="text-lg mx-2 text-gray-700">
                    创建日期:
                  </strong>
                  <span className="text-gray-600">
                    {new Date(selectedCase.created_at).toLocaleDateString()}
                  </span>
                </p>
              </div>
            </div>
          ) : (
            <>
              <h1 className="text-2xl font-bold mb-6 text-left">
                当前活跃案件
              </h1>
              {loading && (
                <div className="text-center">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 mx-auto"></div>
                </div>
              )}
              {error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                  {error}
                </div>
              )}
              {!loading && !error && (
                <div className="overflow-x-auto space-y-6 bg-white rounded-lg shadow-md p-8 text-left">
                  <table className="min-w-full">
                    <thead className="bg-gray-100">
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          案件编号
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          全名
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          国籍
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          年龄
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          教育背景
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          兴趣爱好
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          目标国家
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          目标专业
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          学术成绩
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          成绩类型
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          语言能力
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          签证要求
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          住宿需求
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          年度预算
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          提供的文件
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          其他需求
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          状态
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {cases.map((case_) => (
                        <tr
                          key={case_.id}
                          className="hover:bg-slate-200 cursor-pointer"
                          onClick={() => toggleRow(case_)}
                        >
                          <td className="px-6 py-4 whitespace-nowrap text-left">
                            {case_.case_number}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-left">
                            {case_.full_name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-left">
                            {case_.nationality}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-left">
                            {case_.age}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-left">
                            {case_.education_background}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-left">
                            {case_.interests}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-left">
                            {case_.target_country}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-left">
                            {case_.target_major}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-left">
                            {case_.academic_score}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-left">
                            {case_.score_type}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-left">
                            {case_.language_proficiency}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-left">
                            {case_.visa_requirement}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-left">
                            {case_.accommodation_needs}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-left">
                            {case_.annual_budget}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-left">
                            {case_.provided_documents}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-left">
                            {case_.additional_needs}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-left">
                            {case_.status}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default ActiveCases;
