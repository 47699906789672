import React from 'react';
import { useForm } from 'react-hook-form';
import { CaseDetails } from '../types/case';

interface CaseFormProps {
    onSubmit: (data: CaseDetails) => void;
    isLoading: boolean;
}

export const CaseForm: React.FC<CaseFormProps> = ({ onSubmit, isLoading }) => {
    const { register, handleSubmit, formState: { errors } } = useForm<CaseDetails>();

    return (
        <div className="bg-white p-6 rounded-lg shadow-md text-left" dir="rtl">
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                <div>
                    <label className="block text-sm font-medium mb-1">案件编号</label>
                    <input
                        {...register('case_number', { required: '案件编号是必需的' })}
                        className="w-full p-2 border rounded text-left"
                    />
                    {errors.case_number && (
                        <p className="text-red-500 text-sm">{errors.case_number.message}</p>
                    )}
                </div>

                <div>
                    <label className="block text-sm font-medium mb-1">全名</label>
                    <input
                        {...register('full_name', { required: '全名是必需的' })}
                        className="w-full p-2 border rounded text-left"
                    />
                    {errors.full_name && (
                        <p className="text-red-500 text-sm">{errors.full_name.message}</p>
                    )}
                </div>

                <div>
                    <label className="block text-sm font-medium mb-1">国籍</label>
                    <input
                        {...register('nationality', { required: '国籍是必需的' })}
                        className="w-full p-2 border rounded text-left"
                    />
                </div>

                <div>
                    <label className="block text-sm font-medium mb-1">年龄</label>
                    <input
                        type="number"
                        {...register('age', { required: '年龄是必需的' })}
                        className="w-full p-2 border rounded text-left"
                    />
                    {errors.age && (
                        <p className="text-red-500 text-sm">{errors.age.message}</p>
                    )}
                </div>

                <div>
                    <label className="block text-sm font-medium mb-1">教育背景</label>
                    <select
                        {...register('education_background', { required: '教育背景是必需的' })}
                        className="w-full p-2 border rounded text-left"
                    >
                        <option value="">选择教育背景</option>
                        <option value="high_school">高中</option>
                        <option value="bachelors">本科学位</option>
                        <option value="masters">硕士学位</option>
                        <option value="doctorate">博士学位</option>
                        <option value="vocational">职业培训</option>
                    </select>
                </div>

                <div>
                    <label className="block text-sm font-medium mb-1">兴趣</label>
                    <input
                        {...register('interests', { required: '兴趣是必需的' })}
                        className="w-full p-2 border rounded text-left"
                    />
                    {errors.interests && (
                        <p className="text-red-500 text-sm">{errors.interests.message}</p>
                    )}
                </div>

                <div>
                    <label className="block text-sm font-medium mb-1">目标国家</label>
                    <input
                        {...register('target_country', { required: '目标国家是必需的' })}
                        className="w-full p-2 border rounded text-left"
                    />
                </div>

                <div>
                    <label className="block text-sm font-medium mb-1">目标专业</label>
                    <input
                        {...register('target_major', { required: '目标专业是必需的' })}
                        className="w-full p-2 border rounded text-left"
                    />
                </div>

                <div>
                    <label className="block text-sm font-medium mb-1">学术成绩</label>
                    <input
                        type="number"
                        {...register('academic_score', { required: '学术成绩是必需的' })}
                        className="w-full p-2 border rounded text-left"
                    />
                    {errors.academic_score && (
                        <p className="text-red-500 text-sm">{errors.academic_score.message}</p>
                    )}
                </div>

                <div>
                    <label className="block text-sm font-medium mb-1">成绩类型</label>
                    <select
                        {...register('score_type', { required: '成绩类型是必需的' })}
                        className="w-full p-2 border rounded text-left"
                    >
                        <option value="">选择签证要求</option>
                        <option value="gpa">GPA</option>
                        <option value="percentage">百分比</option>
                    </select>
                </div>

                <div>
                    <label className="block text-sm font-medium mb-1">语言能力</label>
                    <input
                        {...register('language_proficiency', { required: '语言能力是必需的' })}
                        className="w-full p-2 border rounded text-left"
                    />
                </div>

                <div>
                    <label className="block text-sm font-medium mb-1">签证要求</label>
                    <select
                        {...register('visa_requirement', { required: '签证要求是必需的' })}
                        className="w-full p-2 border rounded text-left"
                    >
                        <option value="">选择签证要求</option>
                        <option value="student">学生</option>
                        <option value="work">工作</option>
                        <option value="dependent">依赖</option>
                        <option value="none">无</option>
                    </select>
                </div>

                <div>
                    <label className="block text-sm font-medium mb-1">住宿需求</label>
                    <select
                        {...register('accommodation_needs', { required: '住宿需求是必需的' })}
                        className="w-full p-2 border rounded text-left"
                    >
                        <option value="">选择住宿需求</option>
                        <option value="on_campus">在校内</option>
                        <option value="off_campus">校外</option>
                        <option value="temporary">临时</option>
                        <option value="none">无</option>
                    </select>
                </div>

                <div>
                    <label className="block text-sm font-medium mb-1">年度预算</label>
                    <input
                        type="number"
                        {...register('annual_budget', { required: '年度预算是必需的' })}
                        className="w-full p-2 border rounded text-left"
                    />
                    {errors.annual_budget && (
                        <p className="text-red-500 text-sm">{errors.annual_budget.message}</p>
                    )}
                </div>

                <div>
                    <label className="block text-sm font-medium mb-1">提供的文件</label>
                    <select
                        {...register('provided_documents', { required: '提供的文件是必需的' })}
                        className="w-full p-2 border rounded text-left"
                    >
                        <option value="passport">护照</option>
                        <option value="transcripts">成绩单</option>
                        <option value="financial">财务</option>
                        <option value="language">语言</option>
                        <option value="other">其他</option>
                    </select>
                </div>

                <div>
                    <label className="block text-sm font-medium mb-1">附加需求</label>
                    <input
                        {...register('additional_needs', { required: '附加需求是必需的' })}
                        className="w-full p-2 border rounded text-left"
                    />
                </div>

                <button
                    type="submit"
                    disabled={isLoading}
                    className="w-full bg-blue-600 text-white p-2 rounded hover:bg-blue-700 disabled:bg-blue-300"
                >
                    {isLoading ? '提交中...' : '提交案件'}
                </button>
            </form>
        </div>
    );
};